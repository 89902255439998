.service {
  
  background: url('./image/services.jpg') center center/cover no-repeat; 
   height: 80vh;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
   object-fit: contain;
}
.aboutus_h1 {
  color: white;
 
  font-family: "Open Sans", sans-serif;
  font-size: 3.4em;
  line-height: 1.18em;
}
.square_service {
  height: 381px;
  width:  398px;
 
  margin-left: 5%;
  margin-top: 5%;
  border: none;
}
.square_service1 {
  height: 381px;
  width:  398px;
    
  margin-left: 0%;
  margin-top: 5%;
  border: none;
}
.ser_h1{
  font-size: xx-large;
  font-family: "Open Sans", sans-serif;
  color: black;
}
.ser_p{
width: 100%;
color :black;
text-align: center;
margin-top: 5%;
font-size: medium;
font-family: "Open Sans", sans-serif;
text-align: inherit;
letter-spacing: 1px;
}
.ser_img {
  margin-left: -23%;
  margin-top: 3%;
  width: 137%;
}
.ser_img1 {
  margin-left: 17%;
  width: 67%;
  margin-top: 7%;
}
.ser_img2 {
  width: 65%;
  margin-left: 23%;
  margin-top: 15%;
}
.ser_img3 {
  width: 62%;
  margin-left: 20%;
  margin-top: 15%;
}
.ser_img4 {
  width: 71%;
  margin-left: 13%;
  margin-top: 10%;
}
.flex{
  display: flex;
}
.flex1{
  display: flex;
  margin-left: 40%;
}
.flex2{
  display: flex;
}
.flex3{
  display: flex;
  margin-left: 40%;
}
.flex4{
  display: flex;
}
@media only screen and (min-width:360px) and (max-width:740px){

  .square_service {
    height: 368px;
    width: 392px;
  
   
    margin-left: 0%;
    margin-top: 5%;
    border: none;
  }
  .square_service1 {
    height: 368px;
    width: 392px;;
      
   margin-top: 0%;
  
    border: none;
  }
  .ser_p {
    width: 100%;
    color: black;
    text-align: center;
    margin-top: 5%;
    font-size: 101%;
    font-family: "Open Sans", sans-serif;
    text-align: inherit;
    letter-spacing: 1px;
    margin-left: -1%;
    padding: 16px;
}
  .list
    {
      margin-left: 5%;
  }
  
  .flex{
    display: grid;
    margin-left: 0%;
  }
  .flex1{
    display: grid;
    margin-left: 0%;
  }
  .flex2{
    display: grid;
    margin-left: 0%;
  }
  .flex3{
    display: grid;
    margin-left: 0%;
  }
  .flex4{
    display: grid;
    margin-left: 0%;
  }
  .ser_h1 {
    font-size: x-large;
    font-family: "Open Sans", sans-serif;
    color: black;
}
}
@media only screen and (min-width:360px) and (max-width:740px) and (orientation : landscape){
  .flex{
    display: grid;
   margin-left: 17%;
  }
  .flex1{
   display: grid;
    margin-left: 17%;
  }
  .flex2{
   display: grid;
    margin-left: 17%;
  }
  .flex3{
   display: grid;
    margin-left: 17%;
  }
  .flex4{
   display: grid;
    margin-left: 17%;
  }
  .square_service {
    height: 367px;
width: 400px;
  
    margin-left: 5%;
    margin-top: 5%;
    border: none;
  }
  .square_service1 {
    height: 367px;
    width: 400px;
    
    margin-left: 5%;
    margin-top: 0%;
    border: none;
}
}
@media only screen and (min-width:768px) and (max-width:1024px){
  .flex {
    display: grid;
    margin-left: 16%;
}
  .flex1{
    display: grid;
    margin-left: 16%;
  }
  .flex2{
    display: grid;
    margin-left: 16%;
  }
  .flex3{
    display: grid;
    margin-left: 16%;
  }
  .flex4{
    display: grid;
    margin-left: 16%;
  }
  .ser_p {
    width: 100%;
    color :black;
    text-align: center;
    margin-top: 5%;
    font-size: medium;
    font-family: "Open Sans", sans-serif;
    text-align: inherit;
    margin-left: 6%;
    letter-spacing: 1px;
}
.square_service1 {
  height: 381px;
  width: 398px;
  
  margin-left: 5%;
  margin-top: 0%;
  border: none;
}
}
@media only screen and (min-width:768px) and (max-width:1024px) and (orientation : landscape) {
  .flex{
    display: flex;
    margin-left: 0%;
  }
  .flex1 {
    display: flex;
    margin-left: 0%;
}
  .flex2{
    display: flex;
    margin-left: 0%;
  }
  .flex3{
    display: flex;
    margin-left: 0%;
  }
  .flex4{
    display: flex;
    margin-left: 0%;
  }
  .square_service {
    height: 381px;
    width: 423px;
  
    margin-left: 5%;
    margin-top: 5%;
    border: none;
}
.square_service1 {
  height: 381px;
  width: 423px;
  
  margin-left: 0%;
  margin-top: 5%;
  border: none;
}
}