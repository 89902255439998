.navbar {
  
  height: 0px;
  
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: relative;
  top: 0;
  z-index: 999;
}
.rectangle {
  height: 45px;
  width: 216px;
  /* background-color: #555; */
  margin-left: 2%;
  margin-top: 0%;
}
.unpar {
  font-size: 55%;
  margin-left: 0%;
  font-family: "Open Sans", sans-serif;
  margin-top: 4%;
}
.nav_brady {
  font-size: 124%;
  width: 100%;
  font-family: -webkit-pictograph;
}
.nav-jyo {
  color: black;
  font-size: smaller;
}
.nav-logo {
  width: 6%;
  margin-left: 1%;
}
.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
  margin-left: 0%;
}
.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}
.navimg {
  width: 11%;
  margin-left: -1%;
}
.co {
  width: 15%;
  margin-left: 1%;
  margin-top: -1%;
}
.nav-img {
  width: 20%;
  color: white;
  margin-left: 2%;
  margin-top: 1%;
  font-family: "Open Sans", sans-serif;
  font-size: 22px;
  overflow: TextOverflow-ellipsis;

}

  .span  {
     margin-left: 64%; 
    font-size: 80%;
     margin-top: -13%; 
    font-family: "Open Sans", sans-serif;
    color: #fff;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(9, auto);
  grid-gap: 0px;
  list-style: none;
  text-align: center;
  width: 81vw;
  justify-content: end;
  margin-right: 10rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: white;
  display: flex;
  align-items: center;
  text-decoration: none;
  /* padding: 1.5rem 0rem; */
  height: 100%;
  padding: 12px;
  
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color:white;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media only screen and (min-width:360px) and (max-width:740px){
  .NavbarItems {
    position: relative;
  }
  .co {
    width: 46%;
    margin-left: -53%;
    margin-top: -9%;
}
  .span {
    margin-left: 93%;
    font-size: 80%;
    margin-top: -16%;
    font-family: auto;
}
  .nav_brady {
    font-size: 124%;
    width: 100%;
    font-family: -webkit-pictograph;
  }
  .nav-logo {
    width: 24%;
    margin-left: -219%;
    margin-left: -31%;
    margin-top: -5%;
}
.navimg {
  width: 50%;
  margin-left: 0%;
  margin-top: -2%;
}
.nav-img {
  width: 46%;
  margin-left: -51%;
  font-size: 107%;
  margin-top: -7%;
}
.unpar {
  font-size: small;
  margin-left: 0%;
  width: -1%;
}
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
}

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 0rem;
    width: 100%;
    display: table;
    color: white;
    padding: 1rem;
}

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    margin-top: -2%;
}

  .fa-times {
    color: white;
    font-size: 2rem;
  }

  

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}
@media only screen and (min-width:768px) and (max-width:1024px){
  .NavbarItems {
    position: relative;
  }
  .nav-logo {
    width: 21%;
    margin-left: -27%;
    margin-top: -5%;
}
.co {
  width: 27%;
  margin-left: -72%;
  margin-top: -1%;
}
.nav-img {
  width: 34%;
  margin-left: -62%;
}
.navimg {
  width: 43%;
  margin-left: 0%;
  margin-top: -2%;
}
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
}

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 0rem;
    width: 100%;
    display: table;
    color: white;
    padding: 1rem;
}

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: white;
    font-size: 2rem;
  }

  

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }




}
@media only screen and (min-width:768px) and (max-width:1024px) and (orientation : landscape) {
  .NavbarItems {
    position: relative;
  }
  .nav-logo {
    width: 22%;
    
    margin-left: -27%;
    margin-top: -6%;
}

.nav-img {
  width: 25%;
  margin-left: -72%;
}
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
}

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 0rem;
    width: 100%;
    display: table;
    color: white;
    padding: 1rem;
}

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    margin-top: -1%;
}

  .fa-times {
    color:white;
    font-size: 2rem;
  }

  

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}



@media only screen and (min-width:360px) and (max-width:740px) and (orientation : landscape){
  .nav-jyo {
    color: black;
    font-size: smaller;
    margin-top: -10%;
}
.co {
  width: 30%;
  margin-left: -69%;
  margin-top: -3%;
}
.nav-img {
  width: 27%;
  margin-left: -61%;
  margin-top: -3%;
}
.span {
  margin-left: 78%;
  font-size: 80%;
  margin-top: -16%;
  font-family: auto;
}
.NavbarItems {
  position: relative;
}
.nav-logo {
  width: 19%;
  margin-left: -29%;
  margin-top: -4%;
}
.navimg {
  width: 41%;
  margin-left: 0%;
  margin-top: -2%;
}
.nav-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 110vh;
  position: absolute;
  top: 80px;
  left: -100%;
  opacity: 1;
  transition: all 0.5s ease;
}

.nav-menu.active {
  background: #242222;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 1;
}

.nav-links {
  text-align: center;
  padding: 0rem;
  width: 100%;
  display: table;
  color: white;
  
}

.nav-links:hover {
  background-color: #fff;
  color: #242424;
  border-radius: 0;
}

.navbar-logo {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(25%, 50%);
}

.menu-icon {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-100%, 60%);
  font-size: 1.8rem;
  cursor: pointer;
}

.fa-times {
  color: white;
  font-size: 2rem;
}



.nav-links-mobile:hover {
  background: #fff;
  color: #242424;
  transition: 250ms;
}
}

@media only screen and (min-width:414px) and (max-width:736px){
  .span {
    margin-left: 82%;
    font-size: 80%;
    margin-top: -16%;
    font-family: auto;
}
}


@media only screen and (min-width:414px) and (max-width:736px)and (orientation : landscape){
  .span {
    margin-left: 77%;
    font-size: 80%;
    margin-top: -16%;
    font-family: auto;
}

} 