.aboutus {
  
    background: url('./image/french.jpg') center center/cover no-repeat; 
     height: 80vh;
     width: 100%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
     object-fit: contain;
     
 }
   /* .aboutus_h1 {
    color: white;
  
    font-size: 3.4em;
    line-height: 1.18em;
} */
.aboutus_h1 {
    color: white;
    font-family: "Open Sans", sans-serif;
    font-size: 3.4em;
    line-height: 1.18em;
}
.aboutus_p{
    width: 59%;
    font-size: 150%;
    margin-left: 20%;
    text-align: justify;
    margin-top: 3%;
    font-size: x-large;
    
    font-family: calibri;
}
.law{
    margin-left: 20%;
    width: 50%;
    font-size: x-large;
    font-family: 'Font Awesome 5 Brands';
}
.caro{
    background: url('./image/lawyer.jpg') center center/cover no-repeat; 
     height: 50vh;
     width: 100%;
     display: flex;
     flex-direction: column;
    margin-top: 3%;
     align-items: center;
     box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
     object-fit: contain;
}

@media only screen and (min-width:360px) and (max-width:740px){
.aboutus_p {
    width: 100%;
    font-size: 150%;
    margin-left: 0%;
    text-align: center;
    margin-top: 3%;
    font-size: x-large;
   
    font-family: "Open Sans", sans-serif;
}
.caro{
    background: url('./image/lawyer.jpg') center center/cover no-repeat; 
     height: 80vh;
     width: 100%;
     display: flex;
     flex-direction: column;
    margin-top: 3%;
     align-items: center;
     box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
     object-fit: contain;
}
}
@media only screen and (min-width:768px) and (max-width:1024px){
.caro {
    background: url(/static/media/lawyer.e1eace89.jpg) center center/cover no-repeat;
    height: 30vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}
}
@media only screen and (min-width:768px) and (max-width:1024px) and (orientation : landscape) {
.caro {
    background: url(/static/media/lawyer.e1eace89.jpg) center center/cover no-repeat;
    height: 41vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}
}
@media only screen and (min-width:360px) and (max-width:740px)and (orientation : landscape){

.caro {
    background: url(/static/media/lawyer.e1eace89.jpg) center center/cover no-repeat;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}
}