.cards {
  padding: 4rem;
background-color:white;
}
.card_h1{
  text-align: center;
  
  margin-top: 5%;
  font-size: 2.4em;
    line-height: 3.22em;
    color: rgb(57, 57, 57);
    font-family: "Open Sans", sans-serif;
   
    font-weight: 700;
    text-transform: none;
    margin: 0px;
    width: inherit;

}
.cards_h3{
  font-size: 200%;
  font-family: -webkit-pictograph;
  width: 60%;
  margin-left: 23%;
  text-align: center;
  margin-top: 0%;
}
.card_p {
  margin-top: -3%;
  width: 66%;
  margin-left: 17%;
  font-size: 1.4em;
    line-height: 1.63em;
  color: rgb(57, 57, 57);
    font-family: "Open Sans", sans-serif;
    
    text-transform: none;
  text-align: center;
}

h1 {
  text-align: center;
}
.service-h1 {
  color: rgb(51, 51, 51);
  min-height: auto;
  font-family: "PT Serif";
  line-height: 1.5;
  margin-top: -2%;
}
.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
  margin-left: 5%;
}
.cards_button {
margin-left: 30%;
font-size: 98%;
width: 40%;
border-radius: 12px;
border: none;
color: white;
background: grey;
padding: 5px;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
  background: white;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 60%;
  overflow: hidden;
  margin-left: 0%;
}


.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}
.cards__label  {
  color: rgb(63, 63, 63);
  min-height: auto;
  font-family: "PT Serif";
  line-height: 1.3;
  border-color: rgb(63, 63, 63);
  border-style: none;
  border-width: 0px;
  border-radius: 0px;
  letter-spacing: 0px;
  background-color: rgba(0, 0, 0, 0);
  font-size: larger;
}
/* .cards__item__pic-wrap::after {
  content: attr(data-category);
    position: absolute;
    bottom: 0;
    padding: 6px 8px;
    display: block;
    max-width: calc((100%) - 60px);
    font-size: 15px;
    font-family: cursive;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
} */

.cards__items {
  display: flex;
  width: 114%;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  transition: all 0.2s linear;
  border-end-end-radius: 12px;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: -1px 30px 30px;
}

.cards__item__text {
  color: rgb(124, 128, 131);
  min-height: auto;
  font-family: Poppins;
  padding: 2px;
  line-height: 1.5;
  border-color: rgb(51, 51, 51);
  border-style: none;
  border-width: 0px;
  border-radius: 0px;
  letter-spacing: 0px;
  background-color: rgba(0, 0, 0, 0);
  font-size: inherit;
  text-align: center;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}
@media(max-width:420px){
  .cards__items {
    display: grid;
    width: 114%;
  }
  .cards__item__info {
    padding: 0px 30px 0px;
  }
  .cards__item__text {
    color: #252e48;
    width: 117%;
    margin-left: -7%;
}
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
       
    margin-top: -5%;
    margin-left: -1%;
}
.cards_button {
  margin-left: 17%;
  width: 69%;
}
}
@media only screen and (min-width:360px) and (max-width:740px){

.card_p {
  color: rgb(57, 57, 57);
  font-family: Open Sans, sans-serif;
  font-size: 1.375em;
  line-height: 1.45em;
  font-weight: 400;
  text-transform: none;
  margin: 0px;
  width: inherit;

}
.card_h1 {
  text-align: center;
  margin-top: 5%;
  font-size: 1.7em;
  line-height: 1.22em;
  color: rgb(57, 57, 57);
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  text-transform: none;
  margin: 0px;
  width: 100%;
}
}
@media only screen and (min-width:768px) and (max-width:1024px){
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 60%;
    margin: 0 auto;
        margin-left: auto;
  }
.cards__items {
  display: grid;
  width: 114%;
}
.card_p {
  margin-top: -3%;
  width: 95%;
  margin-left: 3%;
  font-size: 1.4em;
  line-height: 1.63em;
  color: rgb(57, 57, 57);
  font-family: "Open Sans", sans-serif;
  text-transform: none;
  text-align: center;
}
}
@media only screen and (min-width:360px) and (max-width:740px) and (orientation : landscape){
 
 
  .card_h1 {
    text-align: center;
    font-family: auto;
    margin-top: -15%;
    font-size: 2.7em;
    line-height: 1.22em;
    color: rgb(57, 57, 57);
    font-family: "Open Sans", sans-serif;
}
 
 
 
 
 
 
 
 
 
 
 
 
 
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 66%;
    margin: 0 auto;
        margin-left: auto;
    margin-left: 10%;
}
.cards_button {
  margin-left: 32%;
  font-size: 98%;
  width: 36%;
  border-radius: 12px;
  border: none;
  color: white;
  background: grey;
  padding: 5px;
}
.cards__items {
  display: grid;
  width: 114%;
}
.cards__item__text {
  color: rgb(124, 128, 131);
  min-height: auto;
  font-family: Poppins;
  padding: 16px;
  line-height: 1.5;
  border-color: rgb(51, 51, 51);
  border-style: none;
  border-width: 0px;
  border-radius: 0px;
  letter-spacing: 0px;
  background-color: rgba(0, 0, 0, 0);
  font-size: inherit;
  text-align: center;
  width: 98%;
}

}