.contactus {
  
    background: url('./image/contact-banner.jpg') center center/cover no-repeat; 
     height: 80vh;
     width: 100%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
     object-fit: contain;
 }

 .google {
    margin-top: 2%;
    margin-left: 26%;
}
.googlemap {
    width: 709px;
    height: 425px;
}
.google_office{
    font-size: 2.7em;
    line-height: 1.22em;
    color: rgb(51, 51, 51);
    margin-left: -59%;
    margin-top: 3%;
}
.google_offp {
    font-family: "Open Sans", sans-serif;
    line-height: 1.55rem;
    width: 24%;
    margin-left: 5%;
    margin-top: 2%;
    color: rgb(51, 51, 51);
    font-size: x-large;
}
.usefullink {
    margin-left: -64%;
    margin-top: -1%;
    color: black;
    width: 16%;
   
  }
  .google_p {
    font-size: 1.4em;
    line-height: 1.43em;
    font-size: x-large;
    width: 30%;
    color: rgb(51, 51, 51);
    font-family: "Open Sans", sans-serif;
    margin-left: 59%;
    margin-top: 0%;
    font-weight: 400;
}


.goo_p {
    font-size: 1.4em;
    line-height: 1.43em;
    font-size: x-large;
    width: 67%;
    color: rgb(51, 51, 51);
    font-family: "Open Sans", sans-serif;
    margin-left: 17%;
    margin-top: 1%;
    font-weight: 400;
    text-transform: none;
    text-align: center;
}
.googl_h1 {
    margin-left: 0%;
    margin-top: 3%;
    font-size: 2.7em;
    line-height: 1.22em;
    color: rgb(51, 51, 51);
}
.goo_h1 {
    margin-left: 0%;
    margin-top: 3%;
    font-size: 2.7em;
    line-height: 1.22em;
    color: rgb(51, 51, 51);
}
.con_div1 {
    display: grid;
    margin-left: 28%;
    margin-top: 1%;
}
.email_input {
    width: 60.5%;
    padding: 40px;
}
.last {
    margin-left: 0%;
    position: absolute;
    font-family: "Open Sans", sans-serif;
    font-size: larger;
}
.name{
    font-family: "Open Sans", sans-serif;
    font-size: larger;
}
.email_name{
    font-family: "Open Sans", sans-serif;
    font-size: larger;
}
.message_name{ font-family: "Open Sans", sans-serif;
    font-size: larger;}
.last_input {
    margin-left: 0%;
    width: 60.5%;
    margin-top: 2%;
    padding: 8px;
}
.button_input {
    margin-left: 45%;
    margin-top: 2%;
    width: 11%;
    padding: 2px;
    font-size: 167%;
    font-family: auto;
    background: #bfdee8;
}
.name_input {
    width: 60.5%;
    padding: 9px;
}
.con_div {
    display: flex;
    margin-left: 28%;
    margin-top: 2%;
}
.location {
    width: 6%;
    margin-left: 2%;
    margin-top: 4%;
}
.contact-h2 {
    margin-left: 9%;
    font-size: 119%;
    margin-top: -5%;
    font-family: auto;
}
.contact-h1{
    margin-top: 0%;
    
        color: rgb(51, 51, 51);
        min-height: auto;
        font-family: "PT Serif";
        line-height: 1.5;
        border-color: rgb(51, 51, 51);
        border-style: none;
        border-width: 0px;
        border-radius: 0px;
        letter-spacing: 0px;
        background-color: rgba(0, 0, 0, 0);
    
}
.contact-p {
    margin-left: 9%;
    width: 27%;
}
.message {
    width: 8%;
    margin-left: 37%;
    margin-top: -11%;
}
.contact-h21 {
    margin-left: 45%;
    font-size: 119%;
    margin-top: -6%;
    font-family: auto;
    width: 40%;
}
.contact-p1 {
    margin-left: 45%;
}
.contact-table2 {
    margin-left: 37%;
    margin-top: -8%;
}
.contact-table4 {
    margin-left: 56%;
    margin-top: -4%;
}
.contact-table1 {
    margin-left: -1%;
}
.contact-table3{
    margin-left: 13%;
}
.calls {
    width: 7%;
    margin-top: -4%;
    margin-left: 2%;
}
.contact-h2-call {
    margin-left: 9%;
    margin-top: -5%;
}
.contact-p-call {
    margin-left: 9%;
}
.time {
    width: 5%;
    margin-left: 38%;
    margin-top: 5%;
}
.contact-h2-time {
    margin-left: 45%;
    font-size: 119%;
    font-family: auto;
    margin-top: -4%;
}
.contact-p-time {
    margin-left: 45%;
}
@media only screen and (min-width:360px) and (max-width:740px){
    
    .contact-h1 {
        margin-top: 17%;
        color: rgb(51, 51, 51);
        min-height: auto;
        font-family: "PT Serif";
        line-height: 1.5;
        border-color: rgb(51, 51, 51);
        border-style: none;
        border-width: 0px;
        border-radius: 0px;
        letter-spacing: 0px;
        background-color: rgba(0, 0, 0, 0);
    }
    .google {
        margin-top: 5%;
        margin-left: 2%;
    }
    .googlemap {
        width: 399px;
        height: 228px;
    }
    .goo_h1 {
        margin-left: -5%;
        margin-top: 6%;
        font-size: 1.7em;
        line-height: 1.22em;
        color: rgb(51, 51, 51);
    }
    .googl_h1 {
        margin-left: 0%;
        margin-top: 8%;
        font-size: 2.7em;
        line-height: 1.22em;
        color: rgb(51, 51, 51);
    }
    .goo_p {
        font-size: x-large;
        width: 100%;
        font-family: cursive;
        margin-left: 1%;
        margin-top: 3%;
        font-family: "Open Sans", sans-serif;
    }
    .con_div {
        display: grid;
        margin-left: 2%;
        margin-top: 1%;
    }
   
    .last {
        margin-left: 1%;
        
        margin-top: -17%;
    }
    .name_input {
        width: 93%;
        padding: 10px;
    }
    .con_div1 {
        display: grid;
        margin-left: 3%;
        margin-top: 18%;
    }
    .email_input {
        width: 95%;
        padding: 50px;
    }
    .last_input {
        margin-left: 0%;
        width: 95%;
        margin-top: -10.59%;
        padding: 11px;
        
    }
    .button_input {
        margin-left: 31%;
        margin-top: 4%;
        width: 35%;
        font-size: 150%;
        font-family: auto;
        background: #bfdee8;
        padding: 8px;
    }
    .name_input {
        width: 95%;
        padding: 10px;
    }    
    .google_p {
        font-size: x-large;
        width: 100%;
        font-family: cursive;
        margin-left: 3%;
        margin-top: 3%;
        font-family: "Open Sans", sans-serif;
    }
}
@media only screen and (min-width:360px) and (max-width:740px) and (orientation : landscape){
    .googlemap {
        width: 622px;
        height: 300px;
    }
    .google {
        margin-top: 5%;
        margin-left: 8%;
    }
    .con_div1 {
        display: grid;
        margin-left: 3%;
        margin-top: 3%;
    }
    .last {
        margin-left: 0%;
        margin-top: 0%;
    }
    .last_input {
        margin-left: 0%;
        width: 95%;
        margin-top: 3.41%;
        padding: 10px;
    }    
    .location {
        width: 9%;
        margin-left: 9%;
        margin-top: 5%;
    }
    .contact-h2 {
        margin-left: 23%;
        font-size: 119%;
        margin-top: -9%;
        font-family: auto;
    }
    .contact-p {
        margin-left: 23%;
        width: 100%;
    }
    .message {
        width: 8%;
        margin-left: 10%;
        margin-top: 6%;
    }
    .contact-h21 {
        margin-left: 24%;
        font-size: 119%;
        margin-top: -7.35%;
        font-family: auto;
        width: 40%;
    }
    .contact-p1 {
        margin-left: 24%;
    }
    .calls {
        width: 6%;
        margin-top: 8%;
        margin-left: 11%;
    }
    .contact-h2-call {
        margin-left: 24%;
        margin-top: -7%;
        font-size: 119%;
        font-family: auto;
        width: 100%;
    }
    .contact-p-call {
        margin-left: 24%;
        width: 100%;
    }
    .time {
        width: 7%;
        margin-left: 12%;
        margin-top: 4%;
    }
    .contact-h2-time {
        margin-left: 24%;
        font-size: 119%;
        font-family: auto;
        margin-top: -7%;
    }
    .contact-p-time {
        margin-left: 24%;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px){
    .contactus {
        background: url(/static/media/contact-banner.1ec16ac9.jpg) center center/cover no-repeat;
        height: 31vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
        object-fit: contain;
    }
    
    .location {
        width: 9%;
        margin-left: 9%;
        margin-top: 4%;
    }
    .goo_h1 {
        margin-left: 5%;
        margin-top: 3%;
        font-size: 2.7em;
        line-height: 2.22em;
        color: rgb(51, 51, 51);
    }
    .googl_h1 {
        margin-left: 3%;
        margin-top: 8%;
        font-size: 2.7em;
        line-height: 1.22em;
        color: rgb(51, 51, 51);
    }
    .goo_p {
        font-size: x-large;
        width: 100%;
        font-family: cursive;
        margin-left: 2%;
        margin-top: 0%;
        font-family: "Open Sans", sans-serif;
    }
    .con_div {
        display: flex;
        margin-left: 3%;
        margin-top: 1%;
    }
    .last_input {
        margin-left: 0%;
        width: 82.5%;
        margin-top: 3.41%;
        padding: 8px;
        
    }
    .button_input {
        margin-left: 45%;
        margin-top: 1%;
        width: 13%;
        font-size: 150%;
        font-family: auto;
    }    
    .last {
        margin-left: 0%;
        position: absolute;
    }
    .con_div1 {
        display: grid;
        margin-left: 3%;
    }
    .contact-h2 {
        margin-left: 22%;
        font-size: 119%;
        margin-top: -9%;
        font-family: auto;
    }
    .contact-p {
        margin-left: 22%;
        width: 100%;
    }
    .message {
        width: 9%;
        margin-left: 9%;
        margin-top: 5%;
    }
    .contact-h21 {
        margin-left: 22%;
        font-size: 119%;
        margin-top: -8.35%;
        font-family: auto;
        width: 40%;
    }
    .contact-p1 {
        margin-left: 22%;
    }
    .calls {
        width: 8%;
        margin-top: 11%;
        margin-left: 10%;
    }
    .contact-h2-call {
        margin-left: 22%;
        margin-top: -8%;
        font-size: 119%;
        font-family: auto;
        width: 100%;
    }
    .contact-p-call {
        margin-left: 22%;
        width: 100%;
    }
    .time {
        width: 8%;
        margin-left: 11%;
        margin-top: 5%;
    }
    .contact-h2-time {
        margin-left: 22%;
        font-size: 119%;
        font-family: auto;
        margin-top: -8%;
    }
    .contact-p-time {
        margin-left: 22%;
    }
    .google {
        margin-top: 5%;
        margin-left: 4%;
    }
    .google_p {
        font-size: x-large;
        width: 100%;
        font-family: cursive;
        margin-left: 4%;
        margin-top: 2%;
        font-family: "Open Sans", sans-serif;
    }
    .email_input {
        width: 82.5%;
        padding: 10px;
    }
    .name_input {
        width: 82.5%;
        padding: 9px;
    }
}
@media only screen and (min-width:768px) and (max-width:1024px) and (orientation : landscape) {
    .contactus {
        background: url(/static/media/contact-banner.1ec16ac9.jpg) center center/cover no-repeat;
        height: 57vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
        object-fit: contain;
    }
    .con_div1 {
        display: grid;
        margin-left: 3%;
        margin-top: 3%;
    }
    .name_input {
        width: 82%;
        padding: 9px;
    }
    .last_input {
        margin-left: 0%;
        width: 82.5%;
        margin-top: 2.2%;
        padding: 8px;
        
    }
    .last {
        margin-left: 0%;
        
    }
    .goo_p {
        font-size: x-large;
        width: 96%;
        font-family: cursive;
        margin-left: 2%;
        margin-top: 0%;
        font-family: "Open Sans", sans-serif;
    }
    .google {
        margin-top: 5%;
        margin-left: 15%;
    }    
    .google_p {
        font-size: x-large;
        width: 86%;
        font-family: cursive;
        margin-left: 4%;
        margin-top: 2%;
        font-family: "Open Sans", sans-serif;
    }
}

