.team {
    width: 35%;
    margin-left: 10%;
    margin-top: 5%;
    
}
.team_h1{
    font-size: 2.7em;
    line-height: 1.22em;
    margin-top: -22%;
    margin-left: 5%;
    color: rgb(57, 57, 57);
}
.team_p {
    width: 49%;
    margin-left: 50%;
    margin-top: 1%;
    color: rgb(57, 57, 57);
    font-family: "Open Sans", sans-serif;
    font-size: 1.45em;
    line-height: 1.62em;
    font-weight: 400;
}
.team_button{
    margin-left: 50%;
    font-size: 150%;
    margin-top: 1%;
    background: white;
    padding: 5px;
    text-decoration: none;
    color: rgb(57, 57, 57);
    font-family: "Open Sans", sans-serif;
    font-size: 1.45em;
    line-height: 1.62em;
    font-weight: 400;
   
}
@media only screen and (min-width:360px) and (max-width:740px){
    .team {
        width: 100%;
        margin-left: 0%;
        margin-top: 5%;
    }
    .team_h1 {
     
        line-height: 1.22em;
        margin-top: -1%;
        margin-left: 0%;
        font-size: xx-large;
    }
    .team_p {
        width: 100%;
        margin-left: 0%;
        margin-top: 1%;
        font-size: 1.4em;
  line-height: 1.63em;
        color: rgb(57, 57, 57);
        font-family: "Open Sans", sans-serif;
        text-align: center;
    }
    .team_button {
        margin-left: 29%;
        font-size: inherit;
        margin-top: 1%;
        background: white;
        padding: 5px;
    }    
}
@media only screen and (min-width:360px) and (max-width:740px) and (orientation : landscape){
.team_button {
    margin-left: 41%;
    font-size: inherit;
    margin-top: 1%;
    background: white;
    padding: 5px;
}
.team {
    width: 100%;
    margin-left: 0%;
    margin-top: 5%;
    height: 76vh;
}
}

@media only screen and (min-width:768px) and (max-width:1024px){
    .team {
        width: 100%;
        margin-left: 0%;
        margin-top: 5%;
        height: 35vh;
    }
    .team_h1 {
        font-size: 2.7em;
        line-height: 1.22em;
        margin-top: 0%;
        margin-left: 5%;
    }
    .team_p {
        width: 100%;
        margin-left: 2%;
        margin-top: 1%;
        font-size: 1.4em;
        line-height: 1.63em;
              color: rgb(57, 57, 57);
              font-family: "Open Sans", sans-serif;
    }
    .team_button {
        margin-left: 40%;
        font-size: 150%;
        margin-top: 1%;
        background: white;
        padding: 5px;
    }
}
@media only screen and (min-width:768px) and (max-width:1024px)and (orientation : landscape){
    .team {
        width: 100%;
        margin-left: 0%;
        margin-top: 5%;
        height: 45vh;
    }

}