.footer{
    margin-top: 15%;
    width: 100%;
    height: 250px;
}
.square_footer {
    height: 189px;
    width: 300px;
    
    margin-top: -13%;
    position: absolute;
    margin-left: 2%;
}
.square_footer1 {
    height: 189px;
    width: 300px;
   
    margin-top: -13%;
    position: absolute;
    margin-left: 39%;
}
.square_footer2 {
    height: 189px;
    width: 300px;
 
    margin-top: -13%;
    position: absolute;
    margin-left: 77%;
}
.direction{
    display: flex;
}
@media only screen and (min-width:360px) and (max-width:740px){
    .footer {
        margin-top: 15%;
        width: 100%;
        height: 560px;
    }
    .direction{
        display: grid;
    }
    .square_footer {
        height: 189px;
        width: 300px;
        margin-top: -129%;
        position: absolute;
        margin-left: 9%;
    }
    .square_footer1 {
        height: 189px;
        width: 300px;
        margin-top: -83%;
        position: absolute;
        margin-left: 10%;
    }
    .square_footer2 {
        height: 189px;
        width: 300px;
        margin-top: -45%;
        position: absolute;
        margin-left: 10%;
    }
}
@media only screen and (min-width:360px) and (max-width:740px) and (orientation : landscape){
    .square_footer2 {
        height: 189px;
        width: 300px;
        margin-top: -23%;
        position: absolute;
        margin-left: 34%;
    }
    .square_footer {
        height: 189px;
        width: 300px;
        margin-top: -45%;
        position: absolute;
        margin-left: 4%;
    }
    .square_footer1 {
        height: 189px;
        width: 282px;
        margin-top: -45%;
        position: absolute;
        margin-left: 55%;
    }
    .footer {
        margin-top: 15%;
        width: 100%;
        height: 346px;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px){
.footer {
    margin-top: 15%;
    width: 100%;
    height: 343px;
}
.square_footer {
    height: 189px;
    width: 300px;
    margin-top: -40%;
    position: absolute;
    margin-left: 5%;
}
.square_footer2 {
    height: 189px;
    width: 300px;
    margin-top: -40%;
    position: absolute;
    margin-left: 57%;
}
.square_footer1 {
    height: 189px;
    width: 300px;
    margin-top: -16%;
    position: absolute;
    margin-left: 35%;
}
}
@media only screen and (min-width:768px) and (max-width:1024px) and (orientation : landscape) {
    .footer {
        margin-top: 15%;
        width: 100%;
        height: 374px;
    }
    

.square_footer {

    height: 189px;
    width: 300px;
    margin-top: -33%;
    position: absolute;
    margin-left: 5%;

}
.square_footer2 {
    height: 189px;
    width: 300px;
    margin-top: -33%;
    position: absolute;
    margin-left: 62%;
}
}
@media only screen and (min-width:414px) and (max-width:736px){
    

.square_footer {

    height: 189px;
    width: 300px;
    margin-top: -114%;
    position: absolute;
    margin-left: 9%;

}
.square_footer1 {
    height: 189px;
    width: 300px;
    margin-top: -74%;
    position: absolute;
    margin-left: 10%;
}
}
@media only screen and (min-width:414px) and (max-width:736px)and (orientation : landscape){
    .square_footer2 {
        height: 189px;
        width: 300px;
        margin-top: -23%;
        position: absolute;
        margin-left: 34%;
    }
    .square_footer {
        height: 189px;
        width: 300px;
        margin-top: -45%;
        position: absolute;
        margin-left: 4%;
    }
    .square_footer1 {
        height: 189px;
        width: 288px;
        margin-top: -45%;
        position: absolute;
        margin-left: 53%;
    }
    .footer {
        margin-top: 15%;
        width: 100%;
        height: 346px;
    } 
}